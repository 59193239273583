import axios from '@axios'

export default {
  namespaced: true,
  state: {
    establishments: {},
    currentEstablishment: {},
    establishmentsOnlineStores: [],
    establishmentsStores: [],
  },
  getters: {
    currentEstablishment(state) {
      return state.currentEstablishment
    },
    establishments(state) {
      return state.establishments
    },
    establishmentsOnlineStores(state) {
      return state.establishmentsOnlineStores
    },
    establishmentsStores(state) {
      return state.establishmentsStores
    },
  },
  mutations: {
    setCurrentEstablishment(state, payload) {
      state.currentEstablishment = payload
    },
    setEstablishments(state, payload) {
      state.establishmentsOnlineStores = []
      state.establishments = payload
    },
    setOnlineStores(state, payload) {
      state.establishmentsOnlineStores.push(payload)
    },
    setStores(state, payload) {
      state.establishmentsStores.push(payload)
    },
    setEmptyOnlineStores(state) {
      state.establishmentsOnlineStores = []
    },
  },
  actions: {
    fetchEstablishments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/establishments', {
            params,
          })
          .then(response => {
            ctx.commit('setEstablishments', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSuppliersForRestocking(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/establishments/suppliers_for_restocking', {
            params,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEstablishment(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/establishments/${id}`)
          .then(response => {
            ctx.commit('setCurrentEstablishment', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchShortEstablishment(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/establishments/${id}/short_establishment`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addEstablishments(ctx, establishment) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/establishments', {
            establishment,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editEstablishment(ctx, { id, establishment }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/establishments/${id}`, {
            establishment,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
