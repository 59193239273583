import axios from "@axios"
import { needReferences } from "../../@core/utils/store-product-utils"

export default {
  namespaced: true,
  state: {
    currentUser: JSON.parse(localStorage.getItem("userData")),
    establishment: {},
    currentDeliveryOption: localStorage.getItem("delivery_option") || null,
    store: null,
    postalCode: localStorage.getItem("postal_code") || null,
  },
  getters: {
    landingPage(state) {
      return state.establishment?.landing_page
    },
    establishment(state) {
      return state.establishment
    },
    currentDeliveryOption(state) {
      return state.currentDeliveryOption
    },
    store(state) {
      return state.store
    },
    postalCode(state) {
      return state.postalCode
    },
    currentUser(state) {
      return state.currentUser
    },
    storeHasReferences(state) {
      return needReferences(state.store)
    }
  },
  mutations: {
    setEstablishment(state, payload) {
      state.establishment = payload
    },
    setCurrentDeliveryOption(state, payload) {
      state.currentDeliveryOption = payload
    },
    setStore(state, payload) {
      state.store = payload
    },
    setPostalCode(state, payload) {
      state.postalCode = payload
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload
    }
  },
  actions: {
    fetchLandingPage(ctx, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/establishments/${slug}/landing_page`)
          .then((response) => {
            ctx.commit("setEstablishment", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editLandingPage(ctx, params) {
      const { establishment_id, landing_page } = params

      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/establishments/${establishment_id}/landing_page`, {
            landing_page,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
