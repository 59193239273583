import axios from "@axios"

export default {
  namespaced: true,
  state: {
    store_id: null,
    restockingStoreProducts: [],
    automaticRestockings: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
  },
  getters: {
    automaticRestockings(state) {
      return state.automaticRestockings
    },
    getStoreId(state) {
      return state.store_id
    },
    getAutomaticRestockings(state) {
      return state.automaticRestockings.data
    },
    pagination(state) {
      return state.automaticRestockings.meta.pagination
    },
    restockingStoreProducts(state) {
      return state.restockingStoreProducts
    },
    productsCount(state) {
      let total = 0
      state.restockingStoreProducts.forEach((product) => {
        total += 1
      })
      return total
    },
  },
  mutations: {
    setStoreId(state, payload) {
      state.store_id = payload
    },
    setAutomaticRestockings(state, payload) {
      state.automaticRestockings = payload
    },
    setRestockingStoreProducts(state, payload) {
      state.restockingStoreProducts = payload
    },
    pushRestockingStoreProduct(state, payload) {
      const index = state.restockingStoreProducts.findIndex(
        (storeProduct) =>
          storeProduct.store_product_id === payload.store_product_id
      )

      if (index > -1) {
        state.restockingStoreProducts.splice(index, 1, payload)
      } else {
        state.restockingStoreProducts.push(payload)
      }
    },
    removeRestockingStoreProduct(state, payload) {
      const index = state.restockingStoreProducts.findIndex(
        (storeProduct) => storeProduct.store_product_id === payload.id
      )

      if (index === -1) return

      state.restockingStoreProducts.splice(index, 1)
    },
    clearRestockingStoreProducts(state, payload) {
      state.restockingStoreProducts = []
    },
  },
  actions: {
    createAutomaticRestocking(ctx, automatic_restocking) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/automatic_restockings/", {
            automatic_restocking,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateAutomaticRestocking(ctx, automatic_restocking) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/automatic_restockings/${automatic_restocking.id}`, {
            automatic_restocking,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })  
    },
    updateAutomaticRestockingProduct(ctx, automatic_restocking) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/restocking_store_products`, {
            automatic_restocking,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchAutomaticRestockings(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/automatic_restockings/", {
            params,
          })
          .then((response) => {
            ctx.commit("setAutomaticRestockings", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    cancelAutomaticRestocking(ctx, automatic_restocking_id) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/v1/automatic_restockings/cancel_automatic_restocking?id=${automatic_restocking_id}`
          )
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeRestokingProduct(ctx, restocking_product_id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/restocking_store_products/${restocking_product_id}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editRestockingProduct(ctx, restocking_product) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/restocking_store_products/${restocking_product.id}`, {
            automatic_restocking: restocking_product.automatic_restocking,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
