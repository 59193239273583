export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/miscellaneous/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/privacy_policy',
    name: 'privacy-policy',
    component: () => import('@/views/conditions/privacyPolicy.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/terms_of_use',
    name: 'terms-of-use',
    component: () => import('@/views/conditions/termsOfUse.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/stripe-checkout-page',
    name: 'stripe-checkout-page',
    component: () => import('@/views/miscellaneous/StripeCheckoutPage.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
    },
  },
  {
    path: '/faq',
    name: 'pages-faq',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-videos',
    name: 'help-videos',
    component: () => import('@/views/miscellaneous/help.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
