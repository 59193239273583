/* eslint-disable */
import axios from '@axios'
import Localbase from 'localbase'
import router from '@/router'

const state = () => ({
  storeProducts: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        total_items: 0,
      },
    },
  },
  loading: false,
  loadingOffline: false,
  searchProduct: '',
  currentFetchPage: 0,
  lastFetchPage: 1,
  offlineStoreProducts: [],
  favoriteProducts: {
    data: [],
    meta: {},
  },
  availableStoreProducts: [],
  availableStores: [],
})

const getters = {
  storeProducts(state) {
    return state.storeProducts.data
  },
  storeProductsInCategory: (state) => (category) => {
    if (category) {
      return state.storeProducts.data.filter((x) =>
        x.product_attributes.categories_ids.includes(category)
      )
    } else {
      return state.storeProducts.data
    }
  },
  availableStores(state) {
    return state.availableStores
  },
  availableStoreProducts(state) {
    return state.availableStoreProducts.data
  },
  pagination(state) {
    return state.storeProducts.meta.pagination
  },
  loading(state) {
    return state.loading
  },
  loadingOffline(state) {
    return state.loadingOffline
  },
  currentFetchPage(state) {
    return state.currentFetchPage
  },
  lastFetchPage(state) {
    return state.lastFetchPage
  },
  offlineStoreProducts(state) {
    return state.offlineStoreProducts
  },
  storeProductsCount(state) {
    return state.offlineStoreProducts.length
  },
  favoriteProducts(state) {
    return state.favoriteProducts.data
  }
}

const mutations = {
  setStoreProducts(state, payload) {
    payload.data.forEach(product => {product.units = 1})
    state.storeProducts = payload
  },
  loading: (state, payload) => {
    state.loading = payload
  },
  loadingOffline: (state, payload) => {
    state.loadingOffline = payload
  },
  setAvailableStoreProducts(state, payload) {
    state.availableStoreProducts = payload
  },
  setAvailableStores(state, payload) {
    state.availableStores = payload
  },
  setSearchProduct(state, payload) {
    state.searchProduct = payload
  },
  setLastFetchPage(state, payload) {
    state.lastFetchPage = payload
  },
  changeCurrentFetchPage(state, payload) {
    state.currentFetchPage = payload
  },
  setOfflineStoreProducts(state, payload) {
    state.offlineStoreProducts = payload
  },
  setFavoriteProducts(state, payload) {
    state.favoriteProducts = payload
  },
}

const actions = {
  getStoreProducts( context, params = { by_sku: true, offline: false } ) {
    return new Promise((resolve, reject) => {
      let db = new Localbase(`store-${params.by_store}`)
      context.commit('loading', true)

      if (params.offline) {
        db.collection('products')
          .doc({ product_attributes: { sku: params.by_name_or_sku } })
          .get()
          .then(doc => {
            resolve({ data: [{ ...doc }] })
          })
      } else {
        axios
          .get(`/v1/store_products`, {
            params
          })
          .then((response) => {
            if (!params.by_sku) {
              context.commit('setStoreProducts', response.data)
            }
            if (response.data.data[0]) {
              resolve(response.data)
            }
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            context.commit('loading', false)
          })
      }

    })
  },

  fetchPublicStoreProducts(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/store_products/public_products", { params })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchGroupedStoreProducts(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/store_products/grouped", { params })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addStoreProduct(context, store_product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .post(`/v1/store_products`, {
          store_product
        })
        .then((response) => {
          resolve(response)
          // context.dispatch('getStoreProductsStore', { by_store: store_id })
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  editStoreProduct(context, store_product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .put(`/v1/store_products/${store_product.id}`, {
          store_product,
        })
        .then((response) => {
          // context.commit('setStoreProducts', response.data)
          resolve(response.data)
          const product = response.data

          let db

          const DBOpenRequest = window.indexedDB.open(
            `store-${product.store_attributes.id}`,
            1,
          )

          DBOpenRequest.onsuccess = function (event) {
            // store the result of opening the database in the db variable. This is used a lot below
            db = DBOpenRequest.result

            const { id } = product

            const objectStore = db
              .transaction(['products'], 'readwrite')
              .objectStore('products')

            const getProductToEdit = objectStore.get(id)

            getProductToEdit.onsuccess = () => {
              const updateProduct = objectStore.put({
                ...product,
                name: product.product_attributes.name,
                sku: product.product_attributes.sku,
              })

              updateProduct.onsuccess = () => {
                console.log(`producto actualizado: ${product.product_attributes.name}`)
                // When this new request succeeds, run lunr to update product search
                context.dispatch('pos/handleLunr', product.store_attributes.id, {
                  root: true,
                })
                db.close()
              }
            }
          }

        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  async fetchOfflineStoreProducts(context, store_id) {
    let tempProducts = []
    let page = 0
    context.commit('loadingOffline', true)
    let res = null
    do {
      page++
      res = await axios
        .get(`/v1/store_products`,{
          params: {
            by_store: store_id,
            by_active_status: true,
            meta: {
              pagination: {
                page,
                per_page: 200,
              },
            },
          }
        })
      res.data.data.forEach(product => {
        const sku = String(Number(product.product_attributes.sku))
        tempProducts.push({
          ...product,
          sku,
          name: product.product_attributes.sku
        })
      })
      if (page === res.data.meta.pagination.total_pages) {
        context.dispatch('pos/handleLunr2', tempProducts, { root: true })
      }
      context.commit('changeCurrentFetchPage', page)
      context.commit('setLastFetchPage', res.data.meta.pagination.total_pages)

    } while (page <= res.data.meta.pagination.total_pages);
    context.commit('changeCurrentFetchPage', 0)
    context.commit('setLastFetchPage', 1)
    context.commit('loadingOffline', false)
    context.commit('setOfflineStoreProducts', tempProducts)

  },

  starProduct(context, {store_id, store_product_id, is_favorite}) {
    return new Promise(() => {
      axios
        .put(`/v1/store_products/${store_product_id}`, {
          store_product: {
            is_favorite: !is_favorite,
          }
        })
        .then(() => {
          router.push({ name: 'store-products', params: { id: store_id }})
        })
    })
  },
  fetchStoreProduct(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/store_products/${params}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchFavoriteProducts(context, params){    
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/store_products`, {
          params: {
            by_store: params,
            by_is_favorite: true,
            by_is_active: true,
            meta: {
              pagination: {
                per_page: 15,
                page: params.page,
              },
            },
          }
        })
        .then((response) => {
          context.commit('setFavoriteProducts', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
